<template>
  <div class="v-hall-details">
    <div class="v-d-flex v-mb-sm v-cursor-default v-align-items-center">
      <icon-general-credit-card class="v-hall-details--icon v-mr-xs" />
      <div class="v-hall-details--after-icon">
        <span
          class="v-mr-xxs"
          v-html="translate('restaurantSinglePage.payCard')"
        />
        <span
          v-html="
            translate(
              hall.Plactic ? 'restaurantSinglePage.available' : 'restaurantSinglePage.notAvailable'
            )
          "
        />
      </div>
    </div>
    <div class="v-d-flex v-mb-sm v-cursor-default v-align-items-center">
      <icon-general-fork-and-knife class="v-hall-details--icon v-mr-xs" />
      <div class="v-hall-details--after-icon">
        <span
          class="v-mr-xxs"
          v-html="translate('restaurantSinglePage.tableReservation')"
        />
        <span
          v-html="
            translate(
              hall.Reservation ? 'restaurantSinglePage.available' : 'restaurantSinglePage.notAvailable'
            )
          "
        />
      </div>
    </div>
    <div class="v-d-flex v-mb-sm v-cursor-default v-align-items-center">
      <icon-general-parking class="v-hall-details--icon v-mr-xs" />
      <div class="v-hall-details--after-icon">
        <span
          class="v-mr-xxs"
          v-html="translate('restaurantSinglePage.parking')"
        />
        <span
          v-html="
            translate(
              hall.Parking ? 'restaurantSinglePage.available' : 'restaurantSinglePage.notAvailable'
            )
          "
        />
      </div>
    </div>
    <div class="v-d-flex v-mb-sm v-cursor-default v-align-items-center">
      <icon-general-wi-fi class="v-hall-details--icon v-mr-xs" />
      <div class="v-hall-details--after-icon">
        <span
          class="v-mr-xxs"
          v-html="translate('restaurantSinglePage.wiFi')"
        />
        <span
          v-html="
            translate(hall.WiFi ? 'restaurantSinglePage.available' : 'restaurantSinglePage.notAvailable')
          "
        />
      </div>
    </div>
    <div class="v-d-flex v-mb-sm v-cursor-default v-align-items-center">
      <icon-general-duck class="v-hall-details--icon v-mr-xs" />
      <div class="v-hall-details--after-icon">
        <span
          class="v-mr-xxs"
          v-html="translate('restaurantSinglePage.animator')"
        />
        <span
          v-html="
            translate(
              hall.Animator ? 'restaurantSinglePage.available' : 'restaurantSinglePage.notAvailable'
            )
          "
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { RestaurantsParts } from '~types/props'

defineProps<RestaurantsParts>()

const { translate } = useI18nSanitized()
</script>

<style lang="scss">
@use 'assets/variables';

.v-hall-details {
  a,
  a:link,
  a:active,
  a:visited {
    color: variables.$BodyTextColor;
  }

  &--icon {
    width: 1.715rem;
    height: 1.715rem;

    flex: 0 0 1.715rem;
    min-width: 1.715rem;
    min-height: 1.715rem;

    fill: none;
    color: variables.$BodyTextColorLight;
  }

  &--after-icon {
    flex: 0 0 calc(100% - 2.25rem);
    max-width: calc(100% - 2.25rem);
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
